import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import NewPassword from "@/contexts/registrations/NewPassword";
import {
  flashMessageFromResponseError,
  flashNoticeMessage
} from "@/utils/alert_utils";
import { areValuesEmpty, validate, validatePassword } from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
import SocialLoginButtons from "@/components/socialLoginButtons/SocialLoginButtons";
const NewInvitation = (props) => {
  const {
    authenticityToken,
    googleAppId,
    invitationToken,
    invitationExpired,
    invitedEmail,
    redirect_path_from_share_memory_button,
    expirationTime,
    loginLink
  } = props;
  const initialUserFormState = {
    invitation_token: invitationToken,
    password: "",
    redirect_path_from_share_memory_button
  };
  const [userForm, setUserForm] = useState(initialUserFormState);
  const [validations, setValidations] = useState({});
  const { t } = useTranslation();
  const isCreateButtonDisabled = areValuesEmpty(userForm, ["password"]) || !validatePassword(userForm.password);
  const onUpdateUserField = (field, value) => {
    setUserForm({
      ...userForm,
      [field]: value
    });
  };
  const rules = {
    password: { type: "string" }
  };
  const handleCreateClick = () => {
    const [isValid, validationMessages] = validate(userForm, rules);
    if (isValid) {
      setValidations({});
      handleCreateUser();
    } else {
      setValidations(validationMessages);
    }
  };
  const handleCreateUser = async () => {
    const url = route("acceptInvitation");
    const body = {
      authenticity_token: authenticityToken,
      user: {
        ...userForm
      }
    };
    try {
      const response = await Api.utility.post(url, body);
      const data = response.data;
      if (data.invitation_accepted)
        window.location.href = data.redirect_path;
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleResendInvitationClick = async () => {
    const url = route("resendInvitation");
    const body = {
      email: invitedEmail
    };
    try {
      const response = await Api.utility.post(url, body);
      flashNoticeMessage(
        "Email sent! Check your inbox to accept your invitation."
      );
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, invitationExpired ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet devise_form__error_box" }, /* @__PURE__ */ React.createElement("p", { className: "form-error-message devise_form__error_title" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.linkExpired"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.invitationExpired"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.clickToResend"
  )))), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column.is-10-mobile is-5-tablet authentication__button-wrapper" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      id: "resend_invitation_link",
      color: "primary",
      "data-remote": "true",
      "data-method": "post",
      onClick: handleResendInvitationClick
    },
    t(
      "views.settings.projectSettings.userManagement.invitations.edit.resend"
    )
  )))) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet devise_form__information_box" }, /* @__PURE__ */ React.createElement("p", { className: "devise_form__error_title" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.acceptInvite"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.willExpire",
    { expirationTime }
  )))), /* @__PURE__ */ React.createElement(
    NewPassword,
    {
      passwordPlaceholder: t(
        "views.memories.usersNew.placeholders.createPassword"
      ),
      onUpdateUserField,
      userPassword: userForm.password
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: isCreateButtonDisabled,
      onClick: handleCreateClick
    },
    t(
      "views.settings.projectSettings.userManagement.invitations.edit.submitButton"
    )
  ))), /* @__PURE__ */ React.createElement(SocialLoginButtons, null), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text mt-2" }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.alreadyHave"
  ), /* @__PURE__ */ React.createElement("a", { href: loginLink }, t(
    "views.settings.projectSettings.userManagement.invitations.edit.loginHere"
  )))));
};
export default NewInvitation;
