import { experimental_createPersister } from "@tanstack/query-persist-client-core";
import { createStore, get, set, del } from "idb-keyval";
const store = createStore("lifeweb-idb-store", "default");
const idbStore = () => ({
  getItem: async (key) => await get(key, store),
  setItem: async (key, value) => await set(key, value, store),
  removeItem: async (key) => await del(key, store)
});
const idbPersister = experimental_createPersister({
  storage: idbStore()
});
export { idbPersister, idbStore };
