import Button from "@/components/button/Button";
import Checkbox from "@/components/form/Checkbox";
import FilterableMultiSelect from "@/components/form/FilterableMultiSelect";
import Input from "@/components/form/Input";
import InputGroup from "@/components/form/InputGroup";
import Label from "@/components/form/Label";
import Select from "@/components/form/Select";
import TextArea from "@/components/form/TextArea";
import TextEditor from "@/components/form/TextEditor";
import Toggle from "@/components/form/Toggle";
import Typeahead from "@/components/typeahead/Typeahead";
import { H1, H2, H3 } from "@/components/typography";
import React, { useState } from "react";
const Options = ({ children }) => /* @__PURE__ */ React.createElement("div", { className: "p-2 border border-slate-100 mb-8" }, /* @__PURE__ */ React.createElement("p", { className: "mb-2" }, "Options:"), /* @__PURE__ */ React.createElement("div", null, children, " "));
const Title = ({ text }) => /* @__PURE__ */ React.createElement("pre", { className: "bg-transparent mb-4 mt-12 p-0" }, /* @__PURE__ */ React.createElement("code", { className: "bg-none" }, text));
const Playground = () => {
  const buttonColors = [
    { label: "outline", value: "outline" },
    { label: "primary", value: "primary" },
    { label: "secondary", value: "secondary" },
    { label: "white", value: "white" }
  ];
  const initialButtonProps = {
    color: "outline",
    fullWidth: false,
    isDisabled: false,
    isFacebookBtn: false,
    isGoogleBtn: false,
    loading: false,
    onClick: () => null
  };
  const [buttonProps, setButtonProps] = useState(initialButtonProps);
  const [checked, setChecked] = useState(false);
  const [
    selectedFilterableMultiSelectOptions,
    setSelectedFilterableMultiSelectOptions
  ] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputHighlighted, setInputHighlighted] = useState(false);
  const [inputInvalid, setInputInvalid] = useState(false);
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [labelDescription, setLabelDescription] = useState("Label Description");
  const [labelBold, setLabelBold] = useState(false);
  const [labelLabelSize, setLabelLabelSize] = useState("");
  const [labelRequired, setLabelRequired] = useState(false);
  const [textEditorValue, setTextEditorValue] = useState("");
  const [toggleChecked, setToggleChecked] = useState(false);
  const [typeaheadResults, setTypeaheadResults] = useState([]);
  return /* @__PURE__ */ React.createElement("div", { className: "py-12" }, /* @__PURE__ */ React.createElement(H1, { className: "text-center" }, "LifeWeb Playground"), /* @__PURE__ */ React.createElement("div", { className: "flex flex-col divide-y gap-8 max-w-screen-md mx-auto" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "Typography" }), /* @__PURE__ */ React.createElement(H1, null, "Heading 1"), /* @__PURE__ */ React.createElement(H2, null, "Heading 2"), /* @__PURE__ */ React.createElement(H3, null, "Heading 3"), /* @__PURE__ */ React.createElement("h4", null, "Heading 4"), /* @__PURE__ */ React.createElement("p", null, "Paragraph"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("strong", null, "Bold")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("em", null, "Italic")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("u", null, "Underline")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("a", { href: "#" }, "Link"))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<Button />" }), /* @__PURE__ */ React.createElement(Options, null, /* @__PURE__ */ React.createElement(Label, { size: "small", withoutPadding: true }, "Type"), /* @__PURE__ */ React.createElement(
    Select,
    {
      onInput: (e) => setButtonProps((b) => ({
        ...b,
        color: e.target.value
      })),
      options: buttonColors,
      value: buttonProps.color
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Disabled",
      checked: buttonProps.isDisabled,
      onChange: (state) => setButtonProps((b) => ({ ...b, isDisabled: state }))
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Full Width",
      checked: buttonProps.fullWidth,
      onChange: (state) => setButtonProps((b) => ({ ...b, fullWidth: state }))
    }
  )), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: buttonProps.color,
      fullWidth: buttonProps.fullWidth,
      isDisabled: buttonProps.isDisabled,
      isFacebookBtn: buttonProps.isFacebookBtn,
      isGoogleBtn: buttonProps.isGoogleBtn,
      loading: buttonProps.loading
    },
    "Button Text"
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H2, null, "Form Elements"), /* @__PURE__ */ React.createElement(Title, { text: "<Checkbox />" }), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Checkbox Label",
      checked,
      onChange: () => setChecked((c) => !c)
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<FilterableMultiSelect limit={2} />" }), /* @__PURE__ */ React.createElement(
    FilterableMultiSelect,
    {
      limit: 2,
      options: [
        {
          label: "Option 1",
          value: "option1"
        },
        {
          label: "Option 2",
          value: "option2"
        },
        {
          label: "Option 3",
          value: "option3"
        }
      ],
      selected: selectedFilterableMultiSelectOptions,
      setStateFunction: setSelectedFilterableMultiSelectOptions
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<Input />" }), /* @__PURE__ */ React.createElement(Options, null, /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Highlighted",
      checked: inputHighlighted,
      onChange: setInputHighlighted
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Invalid",
      checked: inputInvalid,
      onChange: setInputInvalid
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Read Only",
      checked: inputReadOnly,
      onChange: setInputReadOnly
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Required",
      checked: labelRequired,
      onChange: setLabelRequired
    }
  ), /* @__PURE__ */ React.createElement(
    Checkbox,
    {
      label: "Bold Label",
      checked: labelBold,
      onChange: setLabelBold
    }
  ), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(Label, { withoutPadding: true }, "Label Size"), /* @__PURE__ */ React.createElement(
    Select,
    {
      onInput: (e) => setLabelLabelSize(e.target.value),
      options: [
        { label: "normal", value: "" },
        { label: "small", value: "small" },
        { label: "large", value: "large" }
      ],
      value: labelLabelSize
    }
  ), /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      label: "Label Description",
      onChange: setLabelDescription,
      value: labelDescription,
      withoutPadding: true
    }
  )), /* @__PURE__ */ React.createElement(
    Label,
    {
      isRequired: labelRequired,
      labelSize: labelLabelSize,
      isBold: labelBold,
      description: labelDescription
    },
    "Input Label"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: inputHighlighted,
      isInvalid: inputInvalid,
      onChange: (text) => setInputValue(text),
      readOnly: inputReadOnly,
      required: labelRequired,
      value: inputValue,
      placeholder: "Placeholder"
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<TextArea />" }), /* @__PURE__ */ React.createElement(
    TextArea,
    {
      maxLength: 100,
      onChange: (text) => setInputValue(text),
      value: inputValue,
      placeholder: "Placeholder"
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<TextEditor />" }), /* @__PURE__ */ React.createElement(TextEditor, { onChange: setTextEditorValue, value: textEditorValue })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Title, { text: "<Toggle />" }), /* @__PURE__ */ React.createElement(
    Toggle,
    {
      on: toggleChecked,
      onClick: () => setToggleChecked((c) => !c)
    }
  )), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "max-w-[260px]" }, /* @__PURE__ */ React.createElement(Title, { text: "<Typeahead />" }), /* @__PURE__ */ React.createElement(
    Typeahead,
    {
      availableResults: [
        { id: "1", name: "Option 1" },
        { id: "2", name: "Option 2" },
        { id: "3", name: "Option 3" }
      ],
      emptyResultsText: "No results",
      placeholder: typeaheadResults.length ? `${typeaheadResults.length} selected` : "Type to search",
      selectedResults: typeaheadResults,
      updateResults: setTypeaheadResults,
      isHighlighted: true,
      withDropdownCaret: true
    }
  )))));
};
export { Playground };
