import ProjectUpdateNew from "@/contexts/project_updates/NewProjectUpdate";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { StrictMode } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AccessRestricted from "./contexts/projects/AccessRestricted";
import ProjectHeader from "./contexts/projects/components/ProjectHeader";
import Obituary from "./contexts/projects/Obituary";
import ProjectDetail from "./contexts/projects/ProjectDetail";
import ProjectUpdateDetail from "./contexts/project_updates/ProjectUpdateDetail";
import ProjectUpdateEdit from "./contexts/project_updates/ProjectUpdateEdit";
import ProjectUpdatesIndex from "./contexts/project_updates/ProjectUpdatesIndex";
import NewRegistration from "./contexts/registrations/NewRegistration";
import Settings from "./contexts/settings";
import { full, routes } from "./utils/route_utils";
const queryClient = new QueryClient();
const AuthRoutes = () => /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/users/sign_up", component: NewRegistration }));
const ProjectRoutes = () => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ProjectHeader, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/access_denied", component: AccessRestricted }), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/obituary", component: Obituary }), /* @__PURE__ */ React.createElement(Route, { path: "/:slug/settings", component: Settings }), /* @__PURE__ */ React.createElement(Route, { path: full(routes.projectMemory) }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/updates", component: ProjectUpdatesIndex }), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/updates/new", component: ProjectUpdateNew }), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/updates/:id", component: ProjectUpdateDetail }), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/:slug/updates/:id/edit", component: ProjectUpdateEdit })), /* @__PURE__ */ React.createElement(ProjectDetail, null))));
const App = () => {
  return /* @__PURE__ */ React.createElement(StrictMode, null, /* @__PURE__ */ React.createElement(QueryClientProvider, { client: queryClient }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { path: "/users/sign_up", component: AuthRoutes }), /* @__PURE__ */ React.createElement(Route, { path: "/:slug", component: ProjectRoutes }))), /* @__PURE__ */ React.createElement(ReactQueryDevtools, { initialIsOpen: false })));
};
export default App;
