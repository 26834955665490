import ActionButton from "@/components/navigation/ActionButton";
import Header, {
  HeaderActions,
  HeaderButton,
  HeaderTitle,
  HeaderTitleSection
} from "@/components/navigation/Header";
import NavigationItem from "@/components/navigation/NavigationItem";
import NavigationSection from "@/components/navigation/NavigationSection";
import SharedNav from "@/components/navigation/SharedNav";
import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import { useGroupQuery, useIdentityQuery } from "@/queries";
import { route } from "@/utils/route_utils";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import ShareModal from "../modal/ShareModal";
import StayConnectedModal from "../modal/StayConnectedModal";
import StayConnected from "../StayConnected";
const GroupNavigation = ({
  group,
  onShare,
  user
}) => {
  if (!group)
    return;
  return /* @__PURE__ */ React.createElement(
    "nav",
    {
      id: "main-menu",
      className: "bg-white divide-lifeweb-blue divide-y border-b border-t border-lifeweb-blue shadow-md text-lifeweb-blue",
      "aria-label": "Main menu"
    },
    /* @__PURE__ */ React.createElement(
      NavigationSection,
      {
        link: route("groupShow", { slug: group.slug }),
        title: group.name,
        mobile: true
      },
      /* @__PURE__ */ React.createElement(NavigationItem, { title: "Share", onClick: onShare }),
      (group == null ? void 0 : group.stay_connected_enabled) && /* @__PURE__ */ React.createElement(
        NavigationItem,
        {
          link: route("groupStayConnected", { slug: group.slug }),
          title: "Stay Connected"
        }
      )
    ),
    /* @__PURE__ */ React.createElement(SharedNav, { user })
  );
};
const GroupHeader = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const {
    params: { slug }
  } = useRouteMatch(["/groups/:slug"]);
  const { data: group } = useGroupQuery(slug);
  const { data: user, isLoading: isUserLoading } = useIdentityQuery();
  const navRef = useRef(null);
  useDetectClickOutside(navRef, () => setNavOpen(false));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Header,
    {
      mobileMenu: /* @__PURE__ */ React.createElement(
        GroupNavigation,
        {
          group,
          onShare: () => setShareModalVisible(true),
          user
        }
      )
    },
    /* @__PURE__ */ React.createElement(HeaderTitleSection, null, group && /* @__PURE__ */ React.createElement(HeaderTitle, { link: route("groupShow", { slug: group.slug }) }, group.name), /* @__PURE__ */ React.createElement(HeaderActions, null, /* @__PURE__ */ React.createElement(
      ActionButton,
      {
        icon: "upload",
        label: "Share",
        onClick: () => setShareModalVisible(true)
      }
    ), (group == null ? void 0 : group.stay_connected_enabled) && !isUserLoading && /* @__PURE__ */ React.createElement(StayConnected, { group, user }))),
    /* @__PURE__ */ React.createElement("div", { className: "hidden sm:flex items-center justify-center gap-8 px-8" }, /* @__PURE__ */ React.createElement(
      HeaderButton,
      {
        icon: "helpFilled",
        link: "https://www.lifeweb360.com/contact-us"
      },
      "Help"
    ), (user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement("div", { ref: navRef }, /* @__PURE__ */ React.createElement(
      HeaderButton,
      {
        icon: "profileFilled",
        onClick: () => setNavOpen((current) => !current)
      },
      "My Account"
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "sm:right-6 sm:max-w-[260px] w-full top-[5.5rem] z-40",
          {
            absolute: navOpen,
            hidden: !navOpen,
            "sm:hidden": !(user == null ? void 0 : user.id)
          }
        )
      },
      /* @__PURE__ */ React.createElement(GroupNavigation, { group, user })
    )), !isUserLoading && !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(HeaderButton, { icon: "logIn", link: "/users/sign_in" }, "Log In"), /* @__PURE__ */ React.createElement(HeaderButton, { icon: "signUp", link: "/users/sign_up" }, "Sign Up")))
  ), /* @__PURE__ */ React.createElement(
    ShareModal,
    {
      isVisible: shareModalVisible,
      onClose: () => setShareModalVisible(false),
      group
    }
  ), /* @__PURE__ */ React.createElement(Route, { path: "/groups/:slug/stay-connected" }, /* @__PURE__ */ React.createElement(
    StayConnectedModal,
    {
      group,
      onSuccess: () => setNavOpen(false),
      user
    }
  )));
};
export default GroupHeader;
