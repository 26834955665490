import Button from "@/components/button/Button";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Facebook from "@uppy/facebook";
import FileInput from "@uppy/file-input";
import GoogleDrive from "@uppy/google-drive";
import ImageEditor from "@uppy/image-editor";
import Instagram from "@uppy/instagram";
import XHRUpload from "@uppy/xhr-upload";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const ImageUploader = ({
  onChangePreviewImages,
  afterUpload,
  uppyUploadRef,
  previewImages,
  uploadPath
}) => {
  const { t } = useTranslation();
  const dashboardRef = useRef(null);
  const previewImageRef = useRef([]);
  const [dashboardHidden, setDashboardHidden] = useState(true);
  useEffect(() => {
    previewImageRef.current = previewImages;
  });
  let uppy;
  useEffect(() => {
    uppy = new Uppy({
      debug: process.env.NODE_ENV === "development",
      autoProceed: false,
      restrictions: {
        maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
        allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
        maxNumberOfFiles: FILE_UPLOAD_SETTINGS.maxNumberOfFiles
      }
    }).use(FileInput, {
      target: null,
      pretty: true,
      locale: {
        strings: {
          chooseFiles: ""
        }
      }
    }).use(Dashboard, {
      inline: true,
      target: dashboardRef.current,
      hideProgressAfterFinish: true,
      hideCancelButton: true,
      hideUploadButton: true,
      showRemoveButtonAfterComplete: true,
      proudlyDisplayPoweredByUppy: false
    }).use(ImageEditor, {
      target: Dashboard
    }).use(XHRUpload, {
      endpoint: uploadPath
    });
    uppy.use(GoogleDrive, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    }).use(Facebook, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    }).use(Instagram, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    });
    uppy.on("files-added", onFilesAdded);
    uppy.on("file-editor:complete", (updatedFile) => {
      const editImageIndex = previewImageRef.current.findIndex(
        (image) => image.id === updatedFile.id
      );
      previewImageRef.current[editImageIndex].blob = updatedFile.data;
    });
    uppy.on("complete", onComplete);
    uppy.on("file-removed", onRemove);
    uppyUploadRef.current = () => {
      uppy.upload();
    };
  }, []);
  const onFilesAdded = useCallback(
    (files) => {
      const newPreviewImages = [...previewImageRef.current];
      files.forEach((file) => {
        newPreviewImages.push({
          id: file.id,
          blob: file.data,
          preview: file.preview
        });
      });
      onChangePreviewImages(newPreviewImages);
    },
    [previewImageRef]
  );
  const onRemove = useCallback(
    (file) => {
      const newPreviewImages = previewImageRef.current.filter(
        (img) => img.id !== file.id
      );
      onChangePreviewImages(newPreviewImages);
      if (uppy.getFiles().length === 0) {
        setDashboardHidden(true);
      }
    },
    [previewImageRef]
  );
  const onComplete = (result) => {
    if (result.successful.length > 0) {
      const newImages = [];
      result.successful.forEach((file) => {
        newImages.push({
          id: file.id,
          jsonData: JSON.stringify(file.response.body.data),
          thumbnailUrl: file.preview,
          image_url: file.uploadURL
        });
      });
      afterUpload(newImages);
    }
  };
  const onClickAddImages = () => {
    setDashboardHidden(false);
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("memories-new__uppy", {
        "is-hidden": dashboardHidden
      }),
      ref: dashboardRef
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("column is-6", {
        "is-hidden": !dashboardHidden
      })
    },
    /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClickAddImages }, t("views.memories.actions.addImages", {
      imageCount: FILE_UPLOAD_SETTINGS.maxNumberOfFiles
    }))
  )));
};
export default ImageUploader;
