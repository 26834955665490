import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Modal from "@/components/modal/Modal";
import VideoPreview from "@/components/video_preview/VideoPreview";
import MemoryAddVideo from "@/contexts/memories/modules/MemoryAddVideo";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Facebook from "@uppy/facebook";
import FileInput from "@uppy/file-input";
import GoogleDrive from "@uppy/google-drive";
import ImageEditor from "@uppy/image-editor";
import Instagram from "@uppy/instagram";
import XHRUpload from "@uppy/xhr-upload";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const ImageVideoUploader = (props) => {
  const {
    video_url,
    video_thumbnail_url,
    viewMode,
    onChangePreviewImages,
    onChangeVideo,
    onChangeView,
    afterUpload,
    previewImages,
    uppyUploadRef: uppy,
    uploadPath
  } = props;
  const { t } = useTranslation();
  const dashboardRef = useRef(null);
  const [isVideoModalVisible, setisVideoModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardHidden, setDashboardHidden] = useState(true);
  useEffect(() => {
    uppy.current = new Uppy({
      debug: process.env.NODE_ENV === "development",
      autoProceed: false,
      restrictions: {
        maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
        allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
        maxNumberOfFiles: FILE_UPLOAD_SETTINGS.maxNumberOfFiles
      }
    }).use(FileInput, {
      target: null,
      pretty: true,
      locale: {
        strings: {
          chooseFiles: ""
        }
      }
    }).use(Dashboard, {
      inline: true,
      target: dashboardRef.current,
      hideProgressAfterFinish: true,
      hideCancelButton: true,
      hideUploadButton: true,
      showRemoveButtonAfterComplete: true,
      proudlyDisplayPoweredByUppy: false
    }).use(ImageEditor, {
      target: Dashboard
    }).use(XHRUpload, {
      endpoint: uploadPath
    }).use(GoogleDrive, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    }).use(Facebook, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    }).use(Instagram, {
      target: Dashboard,
      companionUrl: "https://companion.lifeweb360.com/"
    }).on("files-added", onFilesAdded).on("file-editor:complete", (updatedFile) => {
      const editImageIndex = previewImages.findIndex(
        (image) => image.id === updatedFile.id
      );
      previewImages[editImageIndex].blob = updatedFile.data;
    }).on("complete", onComplete).on("file-removed", onRemove);
  }, []);
  useEffect(() => {
    if (!uppy.current || !previewImages)
      return;
    if (previewImages.length > 0 && uppy.current.getFiles().length === 0) {
      previewImages.forEach(({ blob }) => uppy.current.addFile(blob));
      setDashboardHidden(false);
    } else if (!dashboardHidden && previewImages.length === 0) {
      uppy.current.cancelAll();
      setDashboardHidden(true);
    }
  }, [previewImages, uppy]);
  const getImages = () => uppy.current.getFiles().map((file) => ({
    id: file.id,
    blob: file.data,
    preview: file.preview
  }));
  const onFilesAdded = () => {
    onChangeView("img");
    onChangePreviewImages(getImages());
  };
  const onRemove = () => {
    onChangePreviewImages(getImages());
    if (uppy.current.getFiles().length === 0) {
      onChangeView("both");
      setDashboardHidden(true);
    }
  };
  const onComplete = (result) => {
    if (result.successful.length > 0) {
      const newImages = [];
      result.successful.forEach((file) => {
        newImages.push({
          id: file.id,
          jsonData: JSON.stringify(file.response.body.data),
          thumbnailUrl: file.preview,
          image_url: file.uploadURL
        });
      });
      afterUpload(newImages);
    }
  };
  const closeVideoModal = () => {
    setisVideoModalVisible(false);
    onChangeVideo("", "");
  };
  const fetchVideoThumbnail = async () => {
    setIsLoading(true);
    const url = route("memoryVideosThumbnail", {
      video_url
    });
    try {
      const response = await Api.utility.get(url);
      if (Object.keys(response.data).length) {
        const thumbnailUrl = response.data.thumbnail_url;
        onChangeVideo(video_url, thumbnailUrl);
      }
      setisVideoModalVisible(false);
      onChangeView("video");
    } catch (err) {
      flashMessageFromResponseError(err);
    }
    setIsLoading(false);
  };
  const onUpdateField = (_, value) => {
    onChangeVideo(value, "");
  };
  const onRemoveVideo = () => {
    onChangeView("both");
    onChangeVideo("", "");
  };
  const onClickAddImages = () => {
    setDashboardHidden(false);
  };
  const onClickAddVideo = () => {
    setisVideoModalVisible(true);
    setDashboardHidden(true);
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Modal, { isVisible: isVideoModalVisible }, /* @__PURE__ */ React.createElement(
    MemoryAddVideo,
    {
      isLoading,
      video_url,
      onCloseClick: closeVideoModal,
      onSaveClick: fetchVideoThumbnail,
      onUpdateField
    }
  )), viewMode === "video" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    VideoPreview,
    {
      thumbnailUrl: video_thumbnail_url,
      onRemove: onRemoveVideo
    }
  ), /* @__PURE__ */ React.createElement("a", { href: video_url, target: "_blank" }, video_url)), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("memories-new__uppy", {
        hidden: dashboardHidden
      }),
      ref: dashboardRef
    }
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames(
        "gap-2 md:gap-0 grid grid-cols-1 md:grid-cols-11 md:px-8 items-center",
        {
          hidden: viewMode === "video"
        }
      )
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("grid-flow-col md:col-span-5", {
          hidden: !dashboardHidden
        })
      },
      /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClickAddImages }, t("views.memories.actions.addImages", {
        imageCount: FILE_UPLOAD_SETTINGS.maxNumberOfFiles
      }))
    ),
    viewMode === "both" && /* @__PURE__ */ React.createElement("strong", { className: "grid-flow-col md:col-span-1 text-center" }, "or"),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("grid-flow-col md:col-span-5", {
          hidden: viewMode !== "both" && !dashboardHidden
        })
      },
      /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClickAddVideo }, t("views.memories.actions.addVideo"))
    )
  ));
};
export default ImageVideoUploader;
