import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import ValidationLabel from "@/components/form/ValidationLabel";
import SocialLoginButtons from "@/components/socialLoginButtons/SocialLoginButtons";
import { idbStore } from "@/queries/persister";
import {
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { validateEmailAddress } from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
import React, { useState } from "react";
import { submitEnqueuedMemory } from "../memories/MemoriesNew";
const Login = () => {
  const [attemptsRemaining, setAttemptsRemaining] = useState(5);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: ""
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const redirectPath = new URLSearchParams(window.location.search).get(
      "redirect_path"
    );
    const url = route("signIn");
    const body = { user: { email, password, redirect_path: redirectPath } };
    try {
      const {
        data: {
          attempts_remaining,
          flash = "",
          redirect = "",
          user_id,
          validations = {}
        }
      } = await Api.utility.post(url, body);
      if (flash) {
        flashSuccessMessage(flash);
      }
      if (Object.values(validations).some((message) => message)) {
        setAttemptsRemaining(attempts_remaining || 5);
        setValidationMessages(validations);
      } else {
        const queuedMemoryPost = await idbStore().getItem("memory");
        const memoryPath = queuedMemoryPost ? await submitEnqueuedMemory({ user_id }) : "";
        flashSuccessMessage("Signed in successfully.");
        window.location.href = memoryPath || redirect || "/";
      }
    } catch (err) {
      setAttemptsRemaining(err.response.data.attempts_remaining || 5);
      const { validations } = err.response.data;
      if (validations && Object.values(validations).some((message) => message)) {
        setAttemptsRemaining(err.response.data.attempts_remaining);
        setValidationMessages(validations);
      }
      flashMessageFromResponseError(err);
    }
  };
  const valid = validateEmailAddress(email) && password.length >= 8;
  return /* @__PURE__ */ React.createElement("div", { className: "authentication flex flex-col items-center min-h-screen" }, /* @__PURE__ */ React.createElement("div", { className: "mx-auto relative w-full" }, /* @__PURE__ */ React.createElement("h1", { className: "mx-auto mb-12 mt-8" }, "Log In"), /* @__PURE__ */ React.createElement("div", { className: "sm:max-w-sm mx-auto px-4 w-full" }, /* @__PURE__ */ React.createElement(SocialLoginButtons, { loginPage: true }), /* @__PURE__ */ React.createElement("form", { method: "post", onSubmit: handleSubmit }, /* @__PURE__ */ React.createElement("div", { className: "mb-6 mt-4" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: validationMessages.email,
      htmlFor: "email",
      valid: !validationMessages.email
    },
    "Email"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "email",
      type: "email",
      onChange: setEmail,
      placeholder: "Enter your email",
      value: email
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "my-6" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: attemptsRemaining > 0 ? validationMessages.password : /* @__PURE__ */ React.createElement(React.Fragment, null, "Please", " ", /* @__PURE__ */ React.createElement("a", { className: "underline", href: route("newPassword") }, "reset your password")),
      htmlFor: "password",
      valid: !validationMessages.password && attemptsRemaining > 0
    },
    "Password"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "password",
      type: showPassword ? "text" : "password",
      onChange: setPassword,
      onToggle: () => setShowPassword((current) => !current),
      placeholder: "Enter your password",
      toggle: true,
      toggleText: showPassword ? "HIDE" : "SHOW",
      value: password
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "my-8" }, /* @__PURE__ */ React.createElement(Button, { color: "primary", isDisabled: !valid }, "Log In")), /* @__PURE__ */ React.createElement("div", { className: "my-12 font-semibold text-center" }, /* @__PURE__ */ React.createElement("a", { href: route("newPassword"), className: "text-lifeweb-brown" }, "Forgot password?"), /* @__PURE__ */ React.createElement("span", null, " | "), /* @__PURE__ */ React.createElement("a", { href: route("signUpPage"), className: "text-lifeweb-brown" }, "Don't have an account?"))))));
};
export default Login;
