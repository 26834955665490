import Api from "@/api/Api";
import Button from "@/components/button/Button";
import { submitEnqueuedMemory } from "@/contexts/memories/MemoriesNew";
import facebookAuthCallback from "@/helpers/facebook_auth_callback";
import googleAuthCallback from "@/helpers/google_auth_callback";
import { idbStore } from "@/queries/persister";
import { route } from "@/utils/route_utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AnotherSocialAccountLinkModal from "../modal/AnotherSocialAccountLinkModal";
import CreateAccountWithSocialModal from "../modal/CreateAccountWithSocialModal";
import FirstSocialAccountLinkModal from "../modal/FirstSocialAccountLinkModal";
const SocialLoginButtons = (props) => {
  var _a;
  const { loginPage = false, redirectPath: redirectPathQueryString } = props;
  const { t } = useTranslation();
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [firstSocialModalVisible, setFirstSocialModalVisible] = useState(false);
  const [
    createAccountWithSocialModalVisible,
    setCreateAccountWithSocialModalVisible
  ] = useState(false);
  const [anotherSocialModalVisible, setAnotherSocialModalVisible] = useState(false);
  const [authInformation, setAuthInformation] = useState({});
  const [existingSocialProvider, setExistingSocialProvider] = useState("");
  const generatedFormAuthenticityToken = (_a = document.querySelector('meta[name="csrf-token"]')) == null ? void 0 : _a.getAttribute("content");
  const humanizeProvidersName = (obj) => {
    if (Object.keys(obj).length === 0)
      return "";
    const provider = obj.provider;
    return providerName(provider);
  };
  const maybeSubmitEnqueuedMemory = async ({ userId }) => {
    if (!userId || !!!await idbStore().getItem("memory"))
      return;
    const redirectPath = await submitEnqueuedMemory({ user_id: userId });
    if (redirectPath) {
      window.location.href = redirectPath;
      return true;
    }
    return false;
  };
  const providerName = (name) => {
    switch (name) {
      case "facebook":
        return "Facebook";
      case "google_oauth2":
        return "Google";
      default:
        return "";
    }
  };
  const closeAnotherSocialModal = (e) => {
    e.preventDefault();
    setAnotherSocialModalVisible(false);
  };
  const closeCreateAccountWithSocialModal = (e) => {
    e.preventDefault();
    setCreateAccountWithSocialModalVisible(false);
  };
  const closeFirstSocialModal = (e) => {
    e.preventDefault();
    setFirstSocialModalVisible(false);
  };
  const authConfirm = async (e) => {
    var _a2;
    e.preventDefault();
    const url = route("socialUserCreationConfirmation");
    const body = {
      provider_auth_information: authInformation,
      redirect_path: redirectPathQueryString
    };
    const response = await Api.utility.post(url, body);
    const userId = (_a2 = response.data.user) == null ? void 0 : _a2.id;
    if (userId) {
      const redirected = await maybeSubmitEnqueuedMemory({ userId });
      if (redirected)
        return;
    }
    window.location.href = response.data.redirect_path || "/";
  };
  const authAction = async (authActionScenarios, responseCallback) => {
    var _a2, _b, _c, _d;
    setFacebookLoading(false);
    switch (authActionScenarios) {
      case "no_user":
        setCreateAccountWithSocialModalVisible(true);
        break;
      case "social_account_exists":
        const redirectPath = ((_a2 = responseCallback == null ? void 0 : responseCallback.data) == null ? void 0 : _a2.redirect_path) || "/";
        const userId = (_d = (_c = (_b = responseCallback == null ? void 0 : responseCallback.data) == null ? void 0 : _b.user) == null ? void 0 : _c.data) == null ? void 0 : _d.id;
        if (userId) {
          const redirected = await maybeSubmitEnqueuedMemory({ userId });
          if (redirected)
            return;
        }
        window.location.href = redirectPathQueryString || redirectPath;
        break;
      case "one_social_link":
        setFirstSocialModalVisible(true);
        break;
      case "another_social_link":
        setAnotherSocialModalVisible(true);
        break;
    }
  };
  const googleAuthHandler = (e) => {
    e.preventDefault();
    const googleClient = window.google.accounts.oauth2.initCodeClient({
      client_id: window.googleApiAppId,
      scope: "email profile",
      ux_mode: "popup",
      callback: async (response) => {
        var _a2;
        const responseCallback = await googleAuthCallback(
          response,
          generatedFormAuthenticityToken,
          loginPage,
          false
        );
        if (!responseCallback)
          return;
        const { redirect_path: redirectPath, user } = responseCallback.data || {};
        if (loginPage) {
          const providerAuthInfo = responseCallback.data.provider_auth_information;
          const existingSocialProviderInfo = (_a2 = responseCallback.data) == null ? void 0 : _a2.existing_provider;
          setAuthInformation(providerAuthInfo);
          setExistingSocialProvider(existingSocialProviderInfo);
          authAction(
            responseCallback.data.auth_action_scenario,
            responseCallback
          );
        } else {
          if (user) {
            const redirected = await maybeSubmitEnqueuedMemory({
              userId: user.data.id
            });
            if (redirected)
              return;
          }
          window.location.href = redirectPath || "/";
        }
      }
    });
    googleClient.requestCode();
  };
  const facebookAuthHandler = async (e) => {
    setFacebookLoading(true);
    e.preventDefault();
    window.FB.login(
      async (response) => {
        var _a2, _b, _c;
        if (response.status === "connected") {
          try {
            const responseCallback = await facebookAuthCallback(
              response,
              loginPage,
              false
            );
            const redirectPath = responseCallback.data.redirect_path;
            if (loginPage) {
              const providerAuthInfo = responseCallback.data.provider_auth_information;
              const existingSocialProviderInfo = (_a2 = responseCallback.data) == null ? void 0 : _a2.existing_provider;
              setAuthInformation(providerAuthInfo);
              setExistingSocialProvider(existingSocialProviderInfo);
              authAction(
                responseCallback.data.auth_action_scenario,
                responseCallback
              );
            } else {
              setFacebookLoading(false);
              const userId = (_c = (_b = responseCallback.data.user) == null ? void 0 : _b.data) == null ? void 0 : _c.id;
              if (userId) {
                const redirected = await maybeSubmitEnqueuedMemory({ userId });
                if (redirected)
                  return;
              }
              window.location.href = redirectPath || "/";
            }
          } catch (err) {
            setFacebookLoading(false);
          }
        } else {
          setFacebookLoading(false);
        }
      },
      { scope: "public_profile, email" }
    );
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "flex flex-col gap-6 items-center mx-auto md:max-w-sm" }, /* @__PURE__ */ React.createElement(Button, { isGoogleBtn: true, onClick: googleAuthHandler, loading: false }, /* @__PURE__ */ React.createElement(
    "img",
    {
      alt: "Google logo",
      src: require("@/assets/images/icons/google_icon_white_round.svg"),
      className: "social-media-icons"
    }
  ), t("views.memories.usersNew.continueWithGoogleButton")), /* @__PURE__ */ React.createElement(
    Button,
    {
      isFacebookBtn: true,
      onClick: facebookAuthHandler,
      loading: facebookLoading
    },
    /* @__PURE__ */ React.createElement(
      "img",
      {
        alt: "Facebook logo",
        src: require("@/assets/images/icons/facebook_icon_white_round.svg"),
        className: "social-media-icons"
      }
    ),
    t("views.memories.usersNew.continueWithFacebookButton")
  ), /* @__PURE__ */ React.createElement("div", { className: "max-w-xs w-full" }, /* @__PURE__ */ React.createElement("div", { className: "divider my-4" }, "OR"))), /* @__PURE__ */ React.createElement(
    FirstSocialAccountLinkModal,
    {
      visible: firstSocialModalVisible,
      onConfirm: authConfirm,
      onCancel: closeFirstSocialModal,
      newSocialProvider: humanizeProvidersName(authInformation)
    }
  ), /* @__PURE__ */ React.createElement(
    CreateAccountWithSocialModal,
    {
      visible: createAccountWithSocialModalVisible,
      onCancel: closeCreateAccountWithSocialModal,
      onConfirm: authConfirm
    }
  ), /* @__PURE__ */ React.createElement(
    AnotherSocialAccountLinkModal,
    {
      visible: anotherSocialModalVisible,
      onCancel: closeAnotherSocialModal,
      onConfirm: authConfirm,
      newSocialProvider: humanizeProvidersName(authInformation),
      existingSocialProvider: providerName(existingSocialProvider)
    }
  ));
};
export default SocialLoginButtons;
