import React from "react";
import { useTranslation } from "react-i18next";
import ActivityIndicator from "@/components/activity/ActivityIndicator";
import Button from "@/components/button/Button";
import PlayButton from "@/components/button/PlayButton";
import Icon from "@/components/icon/Icon";
import ThemeRail from "@/components/theme/ThemeRail";
import { addHyperlink, sanitizeUserInput } from "@/utils/string_utils";
import { fullName } from "@/utils/user_utils";
const MemoryDisplay = (props) => {
  const {
    isSubmitDisabled,
    memory,
    onEditClick,
    onSubmitClick,
    previewImages,
    projectUser,
    user,
    isSubmittingNewMemory
  } = props;
  const { t } = useTranslation();
  const userName = user ? fullName(user) : "";
  const fullUserName = fullName(memory).trim() || userName;
  const shouldDisplayDescription = previewImages.length === 0 && !memory.video_url && memory.description;
  const videoThumbnailUrl = memory.video_thumbnail_url || require("@/assets/images/project_default.jpg");
  const renderPostOnBehalfOfSomeone = () => {
    if (fullUserName !== userName) {
      return /* @__PURE__ */ React.createElement("p", { className: "memory-show__contributor-label" }, t("form.description.memory.memoryPostOnBehalf", {
        userName,
        contributorName: fullUserName
      }));
    }
  };
  const renderButtons = () => {
    return /* @__PURE__ */ React.createElement("div", { className: "memory-display__button-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-6" }, /* @__PURE__ */ React.createElement("div", { className: "memory-display__edit-button-wrapper" }, /* @__PURE__ */ React.createElement(Button, { color: "white", onClick: onEditClick }, /* @__PURE__ */ React.createElement(Icon, { icon: "arrow" }), t("views.memories.display.editButton")))), /* @__PURE__ */ React.createElement("div", { className: "column is-6 memory-display__right-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "memory-display__submit-button-wrapper" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "primary",
        isDisabled: isSubmitDisabled,
        onClick: onSubmitClick,
        id: "submit-memory-button-top"
      },
      t("views.memories.display.submitButton")
    )))));
  };
  const renderBottomDescription = () => {
    if ((previewImages.length !== 0 || memory.video_url) && memory.description) {
      return /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column memory-display__description-caption-wrapper" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "memory-display__description-caption prose max-w-none",
          dangerouslySetInnerHTML: {
            __html: sanitizeUserInput(
              addHyperlink(memory.description),
              projectUser
            )
          }
        }
      )));
    } else if (memory.video_url) {
      return /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column memory-display__description-caption-wrapper" }));
    }
  };
  const renderRelationshipDescription = () => {
    var _a, _b;
    if (fullUserName === userName && ((memory == null ? void 0 : memory.relationship_description) || ((_a = projectUser == null ? void 0 : projectUser.relationship) == null ? void 0 : _a.description))) {
      return /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column memory-display__section-wrapper with-narrow-padding-top" }, /* @__PURE__ */ React.createElement("p", { className: "memory-display__context-label" }, t("form.label.memory.memoryContext")), /* @__PURE__ */ React.createElement("p", { className: "memory-display__context" }, (memory == null ? void 0 : memory.relationship_description) || ((_b = projectUser == null ? void 0 : projectUser.relationship) == null ? void 0 : _b.description))));
    }
  };
  if (isSubmittingNewMemory) {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          flexDirection: "column",
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto"
        }
      },
      /* @__PURE__ */ React.createElement("p", null, "Submitting your memory. Please do not navigate away from this page while we process your memory."),
      /* @__PURE__ */ React.createElement(ActivityIndicator, null)
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, renderButtons(), previewImages.length > 0 && previewImages.map((image) => {
    return /* @__PURE__ */ React.createElement("div", { key: image.id }, image.preview ? /* @__PURE__ */ React.createElement("img", { src: image.preview, className: "memory-display__image" }) : /* @__PURE__ */ React.createElement(
      "img",
      {
        src: URL.createObjectURL(image.blob),
        className: "memory-display__image"
      }
    ));
  }), shouldDisplayDescription && /* @__PURE__ */ React.createElement("div", { className: "memory-display__description-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "memory-display__description prose max-w-none",
      dangerouslySetInnerHTML: {
        __html: addHyperlink(memory.description)
      }
    }
  )))), memory.video_url && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "memory-display__video-wrapper" }, /* @__PURE__ */ React.createElement("img", { src: videoThumbnailUrl, className: "memory-display__video" }), /* @__PURE__ */ React.createElement(PlayButton, null))), /* @__PURE__ */ React.createElement("div", { className: "memory-display__wrapper" }, memory.text && /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile is-centered" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("p", { className: "memory-display__quote" }, memory.text))), fullUserName && /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column memory-display__section-wrapper" }, /* @__PURE__ */ React.createElement("p", { className: "memory-display__contributor-label" }, t("form.label.memory.memoryContributor")), /* @__PURE__ */ React.createElement("p", { className: "memory-display__contributor" }, fullUserName), renderPostOnBehalfOfSomeone())), renderBottomDescription(), renderRelationshipDescription(), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement(ThemeRail, { themes: memory.themes, selectedThemes: memory.themes })))));
};
export default MemoryDisplay;
