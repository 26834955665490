import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Api from "@/api/Api";
import Button from "@/components/button/Button";
import Input from "@/components/form/Input";
import NewPassword from "@/contexts/registrations/NewPassword";
import ValidationLabel from "@/components/form/ValidationLabel";
import SocialLoginButtons from "@/components/socialLoginButtons/SocialLoginButtons";
import { idbStore } from "@/queries/persister";
import {
  flashMessageFromResponseError,
  flashNoticeMessage
} from "@/utils/alert_utils";
import {
  areValuesEmpty,
  isValueEmpty,
  validateEmailAddress,
  validatePassword
} from "@/utils/form_utils";
import { route } from "@/utils/route_utils";
import { useLocation } from "react-router-dom";
import { submitEnqueuedMemory } from "../memories/MemoriesNew";
const initialUserFormState = {
  email: "",
  first_name: "",
  last_name: "",
  password: ""
};
const NewRegistration = () => {
  var _a;
  const [userForm, setUserForm] = useState(initialUserFormState);
  const [resendInvitation, setResendInvitation] = useState(false);
  const [emailInvited, setEmailInvited] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    email: "",
    first_name: "",
    last_name: ""
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectPath = searchParams.get("redirect_path");
  const redirectedFromStayConnected = redirectPath && searchParams.has("display_stay_connected_modal");
  const redirectedFromNewMemory = (_a = searchParams.get("redirect_path")) == null ? void 0 : _a.includes("/memories/new");
  const { t } = useTranslation();
  const redirectSignInPath = route("signInPage");
  const isCreateButtonDisabled = areValuesEmpty(userForm, [
    "email",
    "first_name",
    "last_name",
    "password"
  ]) || !!validationMessages.email || !validatePassword(userForm.password) || emailInvited || emailTaken;
  useEffect(() => {
    mixpanel.track("sign_up_viewed");
  }, []);
  const updateUserField = (field, value) => {
    setUserForm({
      ...userForm,
      [field]: value
    });
  };
  const checkValidation = (field) => {
    if (field === "email") {
      const valid = validateEmailAddress(userForm.email.toString());
      setValidationMessages((previousMessages) => ({
        ...previousMessages,
        email: !valid ? "Invalid email address" : ""
      }));
      if (valid)
        handleCheckEmail(userForm.email);
    }
    if (field === "firstName") {
      setValidationMessages((previousMessages) => ({
        ...previousMessages,
        first_name: isValueEmpty(userForm.first_name) ? "Your first name is required" : ""
      }));
    }
    if (field === "lastName") {
      setValidationMessages((previousMessages) => ({
        ...previousMessages,
        last_name: isValueEmpty(userForm.last_name) ? "Your last name is required" : ""
      }));
    }
  };
  const createAccountOnClick = async () => {
    const url = route("signUp");
    const body = {
      user: {
        ...userForm
      }
    };
    try {
      const { data } = await Api.utility.post(url, body);
      const queuedMemoryPost = await idbStore().getItem("memory");
      const memoryPath = queuedMemoryPost ? await submitEnqueuedMemory({ user_id: data.signup.data.id }) : "";
      if (memoryPath) {
        window.location.href = memoryPath;
      } else if (redirectedFromStayConnected) {
        window.location.href = `/${redirectPath}?display_stay_connected_modal=true`;
      } else if (redirectPath) {
        window.location.href = redirectPath;
      } else if (data.redirect_path) {
        window.location.href = data.redirect_path;
      } else {
        window.location.href = "/";
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleCheckEmail = async (email) => {
    const url = route("existEmail");
    const body = {
      email
    };
    try {
      const response = await Api.utility.post(url, body);
      const data = response.data;
      setEmailInvited(
        !data.email_taken && data.email_invited && !data.invitation_accepted
      );
      if (data.email_taken) {
        setEmailTaken(true);
        setValidationMessages((previousMessages) => ({
          ...previousMessages,
          email: "Email has already been taken"
        }));
      } else {
        setEmailTaken(false);
        setValidationMessages((previousMessages) => ({
          ...previousMessages,
          email: ""
        }));
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const handleResendInvitationClick = async () => {
    const url = route("resendInvitation");
    const body = {
      email: userForm.email
    };
    try {
      await Api.utility.post(url, body);
      setResendInvitation(true);
      flashNoticeMessage(
        "Email sent! Check your inbox to finish creating your account."
      );
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "authentication signup" }, /* @__PURE__ */ React.createElement("div", { className: "container" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("h1", null, t("views.memories.usersNew.headers.createAccount")))), redirectedFromNewMemory ? /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet devise_form__information_box" }, /* @__PURE__ */ React.createElement("p", { className: "devise_form__error_title" }, t("views.memories.usersNew.headers.loginFirst")), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text devise_form__error_text_with_margin" }, t(
    "views.memories.usersNew.headers.loginFirstParagraph"
  )), /* @__PURE__ */ React.createElement("p", { className: "authentication__text devise_form__error_text" }, t(
    "views.memories.usersNew.headers.loginFirstParagraph2"
  ), /* @__PURE__ */ React.createElement("a", { href: redirectSignInPath }, t("views.memories.usersNew.headers.loginHere"))))) : /* @__PURE__ */ React.createElement(React.Fragment, null), /* @__PURE__ */ React.createElement("div", { className: "pt-8 px-8" }, /* @__PURE__ */ React.createElement(SocialLoginButtons, null)), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile my-2" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet pt-0" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: validationMessages.email,
      htmlFor: "email",
      valid: !validationMessages.email
    },
    "Your email"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "email",
      type: "email",
      onBlur: () => checkValidation("email"),
      onChange: (value) => updateUserField("email", value),
      placeholder: t(
        "views.memories.projectNew.welcome.placeholders.email"
      ),
      value: userForm.email
    }
  ), emailInvited ? /* @__PURE__ */ React.createElement(
    "p",
    {
      style: {
        padding: "0 0 20px",
        color: "black"
      },
      id: "resend_invitation_message"
    },
    !resendInvitation ? /* @__PURE__ */ React.createElement(React.Fragment, null, "Email has already been invited. Please", /* @__PURE__ */ React.createElement(
      "a",
      {
        onClick: handleResendInvitationClick,
        id: "resend_invitation_link"
      },
      " ",
      "click here",
      " "
    ), "to resend invite") : /* @__PURE__ */ React.createElement(React.Fragment, null, "Email sent! Check your inbox to finish creating your account.")
  ) : emailTaken ? /* @__PURE__ */ React.createElement(
    "p",
    {
      style: {
        padding: "0 0 20px",
        color: "black"
      }
    },
    /* @__PURE__ */ React.createElement(
      Trans,
      {
        i18nKey: "views.memories.usersNew.subtexts.existingAccount",
        values: { userEmail: userForm.email }
      },
      /* @__PURE__ */ React.createElement(
        "a",
        {
          className: "underline",
          href: `sign_in?email=${userForm.email}`
        },
        t("views.memories.usersNew.subtexts.logIn")
      )
    )
  ) : /* @__PURE__ */ React.createElement(React.Fragment, null))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile my-0" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet pt-0" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: validationMessages.first_name,
      htmlFor: "first_name",
      valid: !validationMessages.first_name
    },
    "Your first name"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "first_name",
      onBlur: () => checkValidation("firstName"),
      onChange: (value) => updateUserField("first_name", value),
      placeholder: t(
        "views.memories.projectNew.welcome.placeholders.firstName"
      ),
      value: userForm.first_name
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile my-0" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet pt-0" }, /* @__PURE__ */ React.createElement(
    ValidationLabel,
    {
      error: validationMessages.last_name,
      htmlFor: "last_name",
      valid: !validationMessages.last_name
    },
    "Your last name"
  ), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "last_name",
      onBlur: () => checkValidation("lastName"),
      onChange: (value) => updateUserField("last_name", value),
      placeholder: t(
        "views.memories.projectNew.welcome.placeholders.lastName"
      ),
      value: userForm.last_name
    }
  ))), /* @__PURE__ */ React.createElement(
    NewPassword,
    {
      passwordPlaceholder: t(
        "views.memories.usersNew.placeholders.createPassword"
      ),
      onUpdateUserField: updateUserField,
      userPassword: userForm.password
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-10-mobile is-5-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "primary",
      isDisabled: isCreateButtonDisabled,
      onClick: createAccountOnClick
    },
    t("views.memories.usersNew.createAccountButton")
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-5-tablet authentication__text" }, /* @__PURE__ */ React.createElement("p", { className: "mb-4 text-base" }, "Already have an account?", " ", /* @__PURE__ */ React.createElement("a", { className: "text-base", href: route("signInPage") }, "Log In")), /* @__PURE__ */ React.createElement(Trans, { i18nKey: "legal.account" }, /* @__PURE__ */ React.createElement("a", { href: t("legal.termsOfUse"), target: "_blank" }), /* @__PURE__ */ React.createElement("a", { href: t("legal.privacyPolicy"), target: "_blank" })))))));
};
export default NewRegistration;
